import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-md);
  gap: var(--spacing-xs);
  background-color: var(--banner-info);
`;
