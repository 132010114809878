import { RMLink } from '@/components/RMLink/RMLink';
import { RMText } from '@/components/RMText/RMText';

import { Container } from './RMPageBanner.styles';

export interface RMPageBannerProps {
  message: string;
  action?: string | null;
  onAction?: () => void;
}

export function RMPageBanner({ message, action, onAction }: RMPageBannerProps) {
  return (
    <Container>
      <RMText type="sans" size="s" bold color="on-surface-primary">
        {message}
      </RMText>
      {action && <RMLink onClick={onAction}>{action}</RMLink>}
    </Container>
  );
}
